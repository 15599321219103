<template>
  <div>
          <v-toolbar dense color="transparent" class="mt-5 pl-15" flat v-if="$vuetify.breakpoint.mdAndUp">

            <v-toolbar-title>
              <v-img 
                :src="require('@/assets/img/Genius.png')" alt="Essay Genius" 
                width="295px"
                height="53px"
              />
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn text>
              Home
            </v-btn>

            <v-btn text>
              Product
            </v-btn>

            <v-btn text>
              Pricing
            </v-btn>

            <v-btn text>
              About
            </v-btn>

            <v-btn text>
              Contact
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-twitter</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn outlined to="/auth/register"> Signup</v-btn>
            <v-btn  class="ml-3" :color="essayOrange" to="/auth/login"> Login</v-btn>
          </v-toolbar>
          <v-toolbar color="transparent" class="mt-5" flat v-else>
            <v-app-bar-nav-icon>
              <v-img 
                :src="require('../../public/favicon.png')" alt="Essay Genius" 
                width="40px"
              />
            </v-app-bar-nav-icon>
            <v-toolbar-title>
              <i class="">Essay <br> Genius</i>
            </v-toolbar-title>      
            <v-spacer></v-spacer>

            <v-btn outlined to="/auth/register"> Signup</v-btn>
            <v-btn  class="ml-3" :color="essayOrange" to="/auth/login"> Login</v-btn>
          </v-toolbar>
        </div>
</template>

<script>
import colorMxn from '@/mixins/colorMxn';

export default {
  name: 'HomeNavbar',
  mixins: [colorMxn],
}
</script>